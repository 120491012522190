import * as React from "react";
import { Input } from "~/components/ui/input";
import { Search } from "lucide-react";
import { useParams } from "@remix-run/react";

function useIsMobile() {
  const [isMobile, setIsMobile] = React.useState(false);

  React.useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 768px)"); // You can adjust the width threshold

    // Update state based on the initial value
    setIsMobile(mediaQuery.matches);

    // Define a callback function to handle screen size changes
    const handleResize = (e: any) => {
      setIsMobile(e.matches);
    };

    // Add event listener for media query change
    mediaQuery.addEventListener("change", handleResize);

    // Cleanup function to remove event listener
    return () => {
      mediaQuery.removeEventListener("change", handleResize);
    };
  }, []);

  return isMobile;
}

export function SearchDialog() {
  const inputRef = React.useRef<HTMLInputElement | null>(null);
  const { word } = useParams();
  const isMobile = useIsMobile();

  React.useEffect(() => {
    // Focus the input element when the component is mounted
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);
  React.useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (e.key === "k" && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        if (inputRef.current) {
          inputRef.current.focus();
        }
      }
    };

    document.addEventListener("keydown", down);
    return () => document.removeEventListener("keydown", down);
  }, []);

  return (
    <div className="w-full flex flex-col items-center justify-center relative">
      <div className="relative flex-1 md:grow-0 ">
        <Search className="absolute left-2.5 top-2.5 h-4 w-4 text-muted-foreground" />
        <Input
          ref={inputRef}
          name="search"
          type="search"
          defaultValue={word || ""}
          placeholder="Search..."
          className="rounded-lg bg-background pl-8 md:max-w-lg w-[398px] md:w-[800px]"
        />
        {!isMobile && (
          <kbd className="absolute right-2.5 top-2 pointer-events-none inline-flex h-5 select-none items-center gap-1 rounded border bg-muted px-1.5 font-mono text-[10px] font-medium text-muted-foreground opacity-100">
            <span className="text-xs">⌘</span>K
          </kbd>
        )}
      </div>
    </div>
  );
}
